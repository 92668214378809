<template>
  <div>
    <div class="d-flex justify-content-end">
      <!-- modal de modification -->
      <button
        class="btn btn-success btn-sm mb-4"
        v-b-modal="modal_name"
        title="Modifier le card"
      >
        <i class="fa fa-pencil"></i>
      </button>
      <b-modal :id="modal_name" title="Editer le card" hide-footer size="xl">
        <b-tabs class="tab-minimal vertical-tab">
          <b-tab active v-if="option['collection']">
            <template slot="title">
              <i class="mdi mdi-home-outline"></i> Type de données
            </template>
            <div class="mx-4">
              <!-- choix des types de données à ajouter -->
              <div class="row">
                <div class="col-md-12 mb-3">
                  De quelle manière voulez vous utiliser ce Card?
                </div>
                <div class="col-md-6">
                  <h6>Créer des cards</h6>
                  <button
                    v-if="!collectionChecked"
                    class="btn btn-gradient-success btn-icon-text"
                  >
                    <i class="mdi mdi-check btn-icon-prepend"></i>
                    Individuel
                  </button>
                  <button
                    v-else
                    @click="switchCollection()"
                    class="btn btn-gradient-dark btn-icon-text"
                  >
                    Individuel
                  </button>
                  <p class="text-muted mt-3">
                    Cette méthode consiste à créer un par un tous les cards et
                    leurs contenus. Vous aurez plus de choix sur la conception
                    graphique ( Taille des textes, alignements ... )
                  </p>
                </div>
                <div class="col-md-6">
                  <h6>Créer une collection de card</h6>
                  <button
                    v-if="collectionChecked"
                    class="btn btn-gradient-success btn-icon-text"
                  >
                    <i class="mdi mdi-check btn-icon-prepend"></i>
                    Collection
                  </button>
                  <button
                    v-else
                    class="btn btn-gradient-dark btn-icon-text"
                    @click="switchCollection()"
                  >
                    Collection
                  </button>
                  <!-- <b-button disabled class="btn btn-gradient-dark btn-fw" style="cursor:not-allowed">Collection</b-button> -->
                  <p class="text-muted mt-3">
                    Cette méthode consiste à combiner une 'entité' avec un card.
                    Ceux-ci seront générés automatiquement. Il sera impossible
                    de modifier les contenus du card. Exemple: cards de produits
                  </p>
                </div>
              </div>
              <!--row -->

              <!-- Sélection de l'entité à combiner avec la collection de card -->
              <div class="row">
                <div class="col-md-6 offset-md-6" v-if="collectionChecked">
                  <b-form-group
                    label="Choisir l'entité à combiner avec le card"
                    label-for="entitytype"
                  >
                    <b-form-select
                      id="entitytype"
                      v-model="selectedEntitytype"
                      :options="entitytypes"
                      value-field="entitytype"
                      text-field="entitytype"
                      placeholder="Sélectionnez l'entité à associer"
                      size="lg"
                    >
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab>
            <template slot="title">
              <i class="mdi mdi-account"></i> Format
            </template>
            <div class="mx-4">
              <div class="card" style="width: 100%">
                <div class="card-body">
                  <h5 class="text-muted">Element à afficher</h5>
                  <hr />
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="selectedDisplay"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <b-form-checkbox
                        v-for="(op, opid) in option['display']"
                        :key="opid"
                        :value="op['value']"
                        >{{ op["titre"] }}</b-form-checkbox
                      >
                    </b-form-checkbox-group>
                  </b-form-group>
                  <p>{{ selectedDisplay }}</p>

                  <h5 class="text-muted">Format d'affichage</h5>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="scrollcards"
                      name="scrollcards"
                      :aria-describedby="ariaDescribedby"
                    >
                      Scrollable
                      {{ scrollcards }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </div>
          </b-tab>
          <div class="d-flex justify-content-end">
            <button class="btn-link mx-4">Annuler</button>
            <button class="btn btn-primary" @click="saveCard()">
              Enregistrer
            </button>
          </div>
        </b-tabs>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "edit-card",
  data: function () {
    return {
      selectedEntitytype: [],
      collectionChecked: false,
      selectedDisplay: [],
      scrollcards: false,
    };
  },
  props: {
    modal_name: String,
    entitytypes: null,
    option: null,
    parentData:null,
  },
  methods: {
    saveCard() {
      let data = {
        collection: this.collectionChecked,
        entity: this.selectedEntitytype,
        display: this.selectedDisplay,
        scroll: this.scrollcards,
      };
      this.$emit("edit-card-com", data);
    },
    //méthodes dans les éditions
    switchCollection() {
      this.$swal({
        title: "Voulez vous changer de méthode de création?",
        text: "Toutes les données de card de cette section seront effacées",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Valider",
        cancelButtonText: "Annuler",
      }).then((result) => {
        if (result.value) {
          !this.collectionChecked
            ? this.$swal(
                "Collection!",
                "En quelques clics, générez votre card en combinant votre card à une entité",
                "success",
                (this.collectionChecked = !this.collectionChecked)
                // this.collectionComponentKey += 1,
                // this.individualCardComponentKey += 1
              )
            : this.$swal(
                "Card individuel!",
                "Créez votre propre card selon votre goût",
                "success",
                (this.collectionChecked = !this.collectionChecked)
                // this.collectionComponentKey += 1,
                // this.individualCardComponentKey += 1
              );
        }
      });
    },
  },
  mounted(){
    this.selectedDisplay = this.parentData['sdisplay']
    this.selectedEntitytype = this.parentData['selentity']
    this.scrollcards = this.parentData['scroll']
  }
};
</script>
<style scoped>
</style>